@font-face {
  font-family: OpenWeatherIcons;
  src: url("OpenWeatherIcons.0ecda6e4.eot");
  src: url("OpenWeatherIcons.0ecda6e4.eot#iefix") format("embedded-opentype"), url("OpenWeatherIcons.fa4882b0.woff2") format("woff2"), url("OpenWeatherIcons.5ed38850.woff") format("woff"), url("OpenWeatherIcons.89afcb43.ttf") format("truetype"), url("OpenWeatherIcons.9fbb72be.svg#OpenWeatherIcons") format("svg");
  font-style: normal;
  font-weight: 400;
}

.owi {
  text-rendering: auto;
  font: 400 14px / 1 OpenWeatherIcons;
  font-size: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  transform: translate(0);
}

.owi-lg {
  vertical-align: -15%;
  font-size: 1.33333em;
  line-height: .75em;
}

.owi-2x {
  font-size: 2em;
}

.owi-3x {
  font-size: 3em;
}

.owi-4x {
  font-size: 4em;
}

.owi-5x {
  font-size: 5em;
}

.owi-fw {
  width: 1.28571em;
  text-align: center;
}

.owi-01d:before {
  content: "";
}

.owi-01n:before {
  content: "";
}

.owi-02d:before {
  content: "";
}

.owi-02n:before {
  content: "";
}

.owi-03d:before {
  content: "";
}

.owi-03n:before {
  content: "";
}

.owi-04d:before {
  content: "";
}

.owi-04n:before {
  content: "";
}

.owi-09d:before {
  content: "";
}

.owi-09n:before {
  content: "";
}

.owi-10d:before {
  content: "";
}

.owi-10n:before {
  content: "";
}

.owi-11d:before {
  content: "";
}

.owi-11n:before {
  content: "";
}

.owi-1232n:before {
  content: "";
}

.owi-13d:before {
  content: "";
}

.owi-13n:before {
  content: "";
}

.owi-50d:before {
  content: "";
}

.owi-50n:before {
  content: "";
}

/*# sourceMappingURL=index.d5ac1c67.css.map */
